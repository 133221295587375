.nice-button {
    background-color: #1940c2;
    color: white;
    font-size: 1em;
    border: none;
    border-radius: 4px;
    padding: 5px;
    font-weight: 300;
}

.nice-button:hover {
    background-color: #577aee;
    cursor: pointer;
}