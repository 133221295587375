.TableRow {
    display: flex;
    position: absolute;
    background-color: #d4e9f0;
    align-items: center;
    width: 100%;
    border-radius: 7px;
}

.TableRow-selected {
    animation: selected infinite 3s linear;
}

@keyframes selected {
    0% {background-color: #c9acdb;}
    50% {background-color: #acb7db}
    100% {background-color: #c9acdb;}
}

.TableRow-name {
    min-width: 400px;
    max-width: 400px;
    flex-grow: 1;
}

.TableRow-cell {
    width: 30px;
    font-weight: bold;
    text-align: center;
    flex-grow: 1;
}

.TableRow-cell-final {
    color: green;
}

.TableRow-cell-pending {
    color: blue;
}

.TableRow-cell-selected {
    color: yellow;
}