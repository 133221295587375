.Table-header {
    text-align: center;
    padding-bottom: 20px;
}

.Table {
    width: 95%;
    margin-left: 2.5%;
    min-height: 800px;
}

.Table-rows {
    position: sticky;
    font-size: 1.2em;
}

.Table-rows > .TableRow:first-child {
    background-color: white;
    font-weight: bold;
}