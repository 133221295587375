body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.Bottom {
    color: white;
    background: #111111;
    height: 100px;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Bottom-logo {
    width: 60px;
}

.Bottom-link {
    color: white;
    padding-left: 8px;
}
.Menu {
    border: 1px solid red;
    min-height: 700px;
    margin: 10px;
    padding: 15px;
}

.Menu-log-area {
    min-width: 700px;
    min-height: 200px;
}

.Menu-contest-code {
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-left: 3px solid #f36d33;
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
}
.nice-button {
    background-color: #1940c2;
    color: white;
    font-size: 1em;
    border: none;
    border-radius: 4px;
    padding: 5px;
    font-weight: 300;
}

.nice-button:hover {
    background-color: #577aee;
    cursor: pointer;
}
.TableRow {
    display: flex;
    position: absolute;
    background-color: #d4e9f0;
    align-items: center;
    width: 100%;
    border-radius: 7px;
}

.TableRow-selected {
    -webkit-animation: selected infinite 3s linear;
            animation: selected infinite 3s linear;
}

@-webkit-keyframes selected {
    0% {background-color: #c9acdb;}
    50% {background-color: #acb7db}
    100% {background-color: #c9acdb;}
}

@keyframes selected {
    0% {background-color: #c9acdb;}
    50% {background-color: #acb7db}
    100% {background-color: #c9acdb;}
}

.TableRow-name {
    min-width: 400px;
    max-width: 400px;
    flex-grow: 1;
}

.TableRow-cell {
    width: 30px;
    font-weight: bold;
    text-align: center;
    flex-grow: 1;
}

.TableRow-cell-final {
    color: green;
}

.TableRow-cell-pending {
    color: blue;
}

.TableRow-cell-selected {
    color: yellow;
}
.Table-header {
    text-align: center;
    padding-bottom: 20px;
}

.Table {
    width: 95%;
    margin-left: 2.5%;
    min-height: 800px;
}

.Table-rows {
    position: -webkit-sticky;
    position: sticky;
    font-size: 1.2em;
}

.Table-rows > .TableRow:first-child {
    background-color: white;
    font-weight: bold;
}
.App {
  border: 1px solid black;
}
